import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  Avatar,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import {
  FacebookShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";

const PostGenerator = () => {
  const [prompt, setPrompt] = useState("");
  const [postContent, setPostContent] = useState("");
  const [postImage, setPostImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [authorId, setAuthorId] = useState("");
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setAuthorId(decodedToken.user._id);
    }
  }, []);

  const handleGeneratePost = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:3088/api/posts/generate",
        { prompt, author: authorId }
      );
      const { title, content, imageUrl } = response.data;
      setPostContent(content);
      setPostImage(imageUrl);
      setSnackbarMessage("Post generated successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Error generating post.");
      setSnackbarSeverity("error");
    }
    setLoading(false);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const toggleContent = () => {
    setShowFullContent((prevShowFullContent) => !prevShowFullContent);
  };

  const shareUrl = window.location.href || "http://example.com";
  const maxLength = 200; // Maximum length for truncated content

  return (
    <Container sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
      <Box sx={{ maxWidth: "800px", width: "100%" }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Post Generator
        </Typography>
        <Box sx={{ mb: 4 }}>
          <TextField
            label="Enter Prompt"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleGeneratePost}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Generate Post"}
          </Button>
        </Box>
        {postContent && (
          <Paper
            elevation={3}
            sx={{
              p: 2,
              mt: 2,
              width: "800px",
              maxWidth: "100%",
              height: "auto",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Avatar
                alt="Author"
                src="https://via.placeholder.com/50"
                sx={{ width: 50, height: 50, mr: 2 }}
              />
              <Box>
                <Typography variant="subtitle1">Author Name</Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment().format("MMMM Do YYYY, h:mm:ss a")}
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              sx={{ whiteSpace: "pre-wrap", fontSize: "14px" }}
            >
              {showFullContent
                ? postContent
                : postContent.length > maxLength
                ? `${postContent.substring(0, maxLength)}...`
                : postContent}
            </Typography>
            {postContent.length > maxLength && (
              <Button onClick={toggleContent}>
                {showFullContent ? "Read Less" : "Read More"}
              </Button>
            )}
            {postImage && (
              <Box sx={{ mt: 2, textAlign: "center" }}>
                <img
                  src={postImage}
                  alt="Generated Post"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                    borderRadius: "8px",
                  }}
                />
              </Box>
            )}
            <Box sx={{ mt: 2, textAlign: "center" }}>
              <FacebookShareButton
                url={shareUrl}
                quote={postContent}
                hashtag="#generatedPost"
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <LinkedinShareButton
                url={shareUrl}
                title={postContent}
                summary={postContent}
                source="Post Generator"
              >
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </Box>
          </Paper>
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default PostGenerator;
