import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const WebsiteContext = createContext();

export const useWebsite = () => useContext(WebsiteContext);

export const WebsiteProvider = ({ children }) => {
  const { id } = useParams();
  const [website, setWebsite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchWebsite = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3088/api/websites/websites/${id}`
        );

        // Extract HTML content between ```html and ```
        const match = response.data.content.match(/```html([\s\S]*?)```/);
        let cleanedContent = match ? match[1].trim() : response.data.content;

        console.log("cleanedContent:", cleanedContent);

        setWebsite({ ...response.data, content: cleanedContent });
      } catch (error) {
        setError("Error fetching website data");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchWebsite();
    }
  }, [id]);

  return (
    <WebsiteContext.Provider
      value={{
        website,
        loading,
        error,
      }}
    >
      {children}
    </WebsiteContext.Provider>
  );
};
