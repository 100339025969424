import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import WebIcon from "@mui/icons-material/Web";
import EmailIcon from "@mui/icons-material/Email";
import PostAddIcon from "@mui/icons-material/PostAdd";
import axios from "axios";

// Registering components necessary for the chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartTitle,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          websitesResponse,
          emailsResponse,
          postsResponse,
          activityResponse,
        ] = await Promise.all([
          axios.get("http://localhost:3088/api/dashboard/total"),
          axios.get("http://localhost:3088/api/dashboard/total"),
          axios.get("http://localhost:3088/api/dashboard/total"),
          axios.get("http://localhost:3088/api/dashboard/monthly"),
        ]);

        const websites = websitesResponse.data.totalWebsites;
        const emails = emailsResponse.data.totalEmails;
        const posts = postsResponse.data.totalPosts;
        const activity = activityResponse.data;

        setData({
          websites,
          emails,
          posts,
          activity,
        });
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6">No data available</Typography>
      </Box>
    );
  }

  const chartData = {
    labels: data.activity.labels,
    datasets: [
      {
        label: "Websites",
        data: data.activity.websites,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Emails",
        data: data.activity.emails,
        backgroundColor: "rgba(54, 162, 235, 0.5)",
      },
      {
        label: "Posts",
        data: data.activity.posts,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Monthly Activity",
      },
    },
  };

  return (
    <Box
      sx={{ flexGrow: 1, p: 3, backgroundColor: "#f5f5f5", minHeight: "100vh" }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              background: "linear-gradient(135deg, #FF6384, #FF9A9E)",
              color: "#ffffff",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.1)" },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h5" component="div">
                  Total Websites
                </Typography>
                <Typography variant="h4" component="div">
                  {data.websites}
                </Typography>
              </Box>
              <IconButton sx={{ color: "white", fontSize: "48px" }}>
                <WebIcon fontSize="inherit" />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              background: "linear-gradient(135deg, #36A2EB, #80D0FF)",
              color: "#ffffff",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.1)" },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h5" component="div">
                  Total Emails
                </Typography>
                <Typography variant="h4" component="div">
                  {data.emails}
                </Typography>
              </Box>
              <IconButton sx={{ color: "white", fontSize: "48px" }}>
                <EmailIcon fontSize="inherit" />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card
            sx={{
              background: "linear-gradient(135deg, #4BC0C0, #92F2E8)",
              color: "#ffffff",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              transition: "transform 0.3s",
              "&:hover": { transform: "scale(1.1)" },
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography variant="h5" component="div">
                  Total Posts
                </Typography>
                <Typography variant="h4" component="div">
                  {data.posts}
                </Typography>
              </Box>
              <IconButton sx={{ color: "white", fontSize: "48px" }}>
                <PostAddIcon fontSize="inherit" />
              </IconButton>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              width: "100%",
              maxWidth: "800px",
              mx: "auto",
              mt: 3,
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
            }}
          >
            <Bar data={chartData} options={chartOptions} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
