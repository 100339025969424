import React from 'react';
import { Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#333',
    color: '#fff',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: theme.spacing(1),
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Typography variant="body2">
        © {new Date().getFullYear()} AI Generator. All rights reserved.
      </Typography>
      <Typography variant="body2" component="p">
        Made with <span role="img" aria-label="heart">❤️</span> by <Link href="https://tekab.dev/" target="_blank" className={classes.link}>TEKAB.dev</Link>
      </Typography>
    </footer>
  );
};

export default Footer;
