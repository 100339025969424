import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserProfile from "./pages/user/UserProfile";
import UserSidebar from "./components/user/UserSidebar";
import AdminSidebar from "./components/admin/AdminSidebar";
import UserList from "./pages/admin/UserList";
import HomePage from "./pages/Home";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import { UserProvider, useUserContext } from "./context/UserContext";
import WebsitesList from "./pages/admin/WebsitesList";
import PostsList from "./pages/admin/PostsList";
import EmailsList from "./pages/admin/EmailsList";
import Dashboard from "./pages/admin/Dashboard";
import WebsiteGenerator from "./pages/user/WebSiteGenerator";
import AuthRedirect from "./hooks/AuthRedirect";
import EmailTemplateGenerator from "./pages/user/EmailTemplateGenerator";
import WebSite from "./pages/user/WebSite";
import { WebsiteProvider } from "./context/WebsiteContext";
import PostGenerator from "./pages/user/PostGenerator";

const AppLayout = ({ children }) => {
  const { userRole, isAuthenticated } = useUserContext();
  const location = useLocation();
  const showSidebar =
    location.pathname !== "/" &&
    location.pathname !== "/login" &&
    location.pathname !== "/register";

  return (
    <div style={{ display: "flex" }}>
      {showSidebar &&
        isAuthenticated &&
        (userRole === "admin" ? <AdminSidebar /> : <UserSidebar />)}
      <div style={{ flexGrow: 1 }}>{children}</div>
      <ToastContainer />
    </div>
  );
};

const App = () => {
  return (
    <UserProvider>
      <Router>
        <AppLayout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/login"
              element={
                <AuthRedirect>
                  <Login />
                </AuthRedirect>
              }
            />
            <Route
              path="/register"
              element={
                <AuthRedirect>
                  <Register />
                </AuthRedirect>
              }
            />
            <Route
              path="/profile"
              element={
                <AuthRedirect>
                  <UserProfile />
                </AuthRedirect>
              }
            />
            <Route
              path="/admin-profile"
              element={
                <AuthRedirect>
                  <UserProfile />
                </AuthRedirect>
              }
            />
            <Route
              path="/website-generator"
              element={
                <AuthRedirect>
                  <WebsiteGenerator />
                </AuthRedirect>
              }
            />
            <Route
              path="/website/:id"
              element={
                <WebsiteProvider>
                  <WebSite />
                </WebsiteProvider>
              }
            />
            <Route
              path="/email-generator"
              element={
                <AuthRedirect>
                  <EmailTemplateGenerator />
                </AuthRedirect>
              }
            />
            <Route
              path="/post-blog-generator"
              element={
                <AuthRedirect>
                  <PostGenerator />
                </AuthRedirect>
              }
            />
            <Route
              path="/admin/users"
              element={
                <AuthRedirect>
                  <UserList />
                </AuthRedirect>
              }
            />
            <Route
              path="/admin/settings"
              element={
                <AuthRedirect>
                  <div>Admin Settings</div>
                </AuthRedirect>
              }
            />
            <Route
              path="/admin/security"
              element={
                <AuthRedirect>
                  <div>Security Settings</div>
                </AuthRedirect>
              }
            />
            <Route
              path="/admin/manage-website-templates"
              element={
                <AuthRedirect>
                  <WebsitesList />
                </AuthRedirect>
              }
            />
            <Route
              path="/admin/manage-posts"
              element={
                <AuthRedirect>
                  <PostsList />
                </AuthRedirect>
              }
            />
            <Route
              path="/admin/manage-email-templates"
              element={
                <AuthRedirect>
                  <EmailsList />
                </AuthRedirect>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <AuthRedirect>
                  <Dashboard />
                </AuthRedirect>
              }
            />
          </Routes>
        </AppLayout>
      </Router>
    </UserProvider>
  );
};

export default App;
