import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Stack,
  FormControl,
  Autocomplete,
  Chip,
  CircularProgress,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { BlockPicker } from "react-color";
import axios from "axios";
import companySpecData from "../../data/CompanySpecData";
import { useNavigate, Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { red } from "@mui/material/colors";

const WebsiteGenerator = () => {
  const [websiteTitle, setWebsiteTitle] = useState("");
  const [websiteDescription, setWebsiteDescription] = useState([]);
  const [primaryColor, setPrimaryColor] = useState("#ffffff");
  const [secondaryColor, setSecondaryColor] = useState("#000000");
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState([
    "Initializing...",
    "Generating website content...",
    "Finalizing...",
    "Almost there...",
    "Just a moment...",
  ]);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [websites, setWebsites] = useState([]);
  const [filteredWebsites, setFilteredWebsites] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [filterTitle, setFilterTitle] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      setUserId(decodedToken.user._id);
      fetchWebsites(decodedToken.user._id);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setCurrentMessageIndex(
          (prevIndex) => (prevIndex + 1) % loadingMessages.length
        );
      }, 10000); // Change message every 10 seconds
      return () => clearInterval(interval);
    }
  }, [loading, loadingMessages.length]);

  const fetchWebsites = async (userId) => {
    try {
      const response = await axios.get(
        `http://localhost:3088/api/websites/${userId}/websites`
      );
      setWebsites(response.data);
      setFilteredWebsites(response.data);
    } catch (error) {
      console.error("Error fetching websites:", error);
      toast.error("Error fetching websites!");
    }
  };

  const handleGenerate = async () => {
    if (!userId) {
      toast.error("User ID not found!");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `http://localhost:3088/api/websites/${userId}/generate-website`,
        {
          title: websiteTitle,
          description: websiteDescription.map((desc) => desc.name).join(", "),
          primaryColor,
          secondaryColor,
        }
      );

      if (response.status === 201) {
        toast.success("Website generated successfully!");
        fetchWebsites(userId); // Refresh the website list
        setTimeout(() => {
          setLoading(false);
          navigate("/profile"); // Uncomment this line if you want to navigate to the profile or the desired route
        }, 1000);
      }
    } catch (error) {
      console.error("There was an error generating the website!", error);
      toast.error("There was an error generating the website!");
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event) => {
    const value = event.target.value;
    setFilterTitle(value);
    const filtered = websites.filter((website) =>
      website.title.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredWebsites(filtered);
  };

  const handleOpenDialog = (websiteId) => {
    setSelectedWebsiteId(websiteId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedWebsiteId(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `http://localhost:3088/api/websites/websites/${selectedWebsiteId}`
      );
      toast.success("Website deleted successfully!");
      fetchWebsites(userId); // Refresh the website list
      handleCloseDialog();
    } catch (error) {
      console.error("Error deleting website:", error);
      toast.error("Error deleting website!");
    }
  };

  return (
    <Container
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Website Generator
      </Typography>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          p: 3,
          border: "1px solid #ccc",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          width: "100%",
          maxWidth: "600px",
          mb: 4,
        }}
      >
        <TextField
          label="Website Title"
          value={websiteTitle}
          onChange={(e) => setWebsiteTitle(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth margin="normal" sx={{ mb: 2 }}>
          <Autocomplete
            multiple
            options={companySpecData}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              setWebsiteDescription(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Website Description"
                placeholder="Search"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
          />
        </FormControl>
        <Stack
          direction="row"
          spacing={2}
          mt={2}
          sx={{ mb: 2, justifyContent: "center" }}
        >
          <Box>
            <Typography variant="body1" gutterBottom>
              Primary Color
            </Typography>
            <BlockPicker
              color={primaryColor}
              onChangeComplete={(color) => setPrimaryColor(color.hex)}
              disableAlpha
            />
          </Box>
          <Box>
            <Typography variant="body1" gutterBottom>
              Secondary Color
            </Typography>
            <BlockPicker
              color={secondaryColor}
              onChangeComplete={(color) => setSecondaryColor(color.hex)}
              disableAlpha
            />
          </Box>
        </Stack>

        <Box mt={2} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerate}
            sx={{ fontWeight: "bold", padding: "10px 20px" }}
            disabled={loading}
          >
            Generate Website
          </Button>
        </Box>
      </Box>

      <TextField
        label="Filter by Title"
        value={filterTitle}
        onChange={handleFilterChange}
        fullWidth
        margin="normal"
        sx={{ mb: 2, maxWidth: "900px" }}
      />

      <TableContainer component={Paper} sx={{ mb: 4, width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Title</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Description</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Primary Color</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Secondary Color</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Published</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredWebsites
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((website) => (
                <TableRow key={website._id}>
                  <TableCell>{website._id}</TableCell>
                  <TableCell>{website.title}</TableCell>
                  <TableCell>{website.description}</TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: website.primaryColor,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        width: 24,
                        height: 24,
                        backgroundColor: website.secondaryColor,
                      }}
                    />
                  </TableCell>
                  <TableCell>{website.published ? "Yes" : "No"}</TableCell>
                  <TableCell>
                    <Link to={`/website/${website._id}`} target="true">
                      <IconButton color="primary">
                        <EditIcon />
                      </IconButton>
                    </Link>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenDialog(website._id)}
                    >
                      <DeleteIcon sx={{ color: red[500] }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[3, 5, 10]}
          component="div"
          count={filteredWebsites.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Modal
        open={loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            width: "80%",
            maxWidth: "600px",
            opacity: 0.95,
            borderRadius: "10px",
          }}
        >
          <CircularProgress size={60} />
          <Typography id="loading-modal-description" sx={{ mt: 2 }}>
            {loadingMessages[currentMessageIndex]}
          </Typography>
        </Box>
      </Modal>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this website? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </Container>
  );
};

export default WebsiteGenerator;
