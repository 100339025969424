import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Pagination,
  Box,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Microlink from "@microlink/react";
import axios from "axios";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  "&:hover": {
    transform: "scale(1.05)", // Zoom effect on hover
    boxShadow: theme.shadows[10], // Enhanced shadow on hover
  },
}));

const WebsitesList = () => {
  const [websites, setWebsites] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [websitesPerPage] = useState(4);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchWebsitesAndUsers = async () => {
      try {
        const [websitesResponse, usersResponse] = await Promise.all([
          axios.get("http://localhost:3088/api/websites/websites"),
          axios.get("http://localhost:3088/api/users/users"),
        ]);
        setWebsites(websitesResponse.data);
        setUsers(usersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchWebsitesAndUsers();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const filteredWebsites = websites.filter((website) =>
    users.find(
      (user) =>
        user._id === website.user &&
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const indexOfLastWebsite = currentPage * websitesPerPage;
  const indexOfFirstWebsite = indexOfLastWebsite - websitesPerPage;
  const currentWebsites = filteredWebsites.slice(
    indexOfFirstWebsite,
    indexOfLastWebsite
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ marginBottom: 2, display: "flex", justifyContent: "center" }}>
        <TextField
          label="Search by Username"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          sx={{ maxWidth: 600 }}
        />
      </Box>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {currentWebsites.map((website) => {
          const user = users.find((user) => user._id === website.user);
          return (
            <Grid item key={website._id}>
              <StyledCard>
                <Microlink
                  url={website.url}
                  size="large"
                  style={{ width: "100%" }}
                />
                <CardContent>
                  <Typography variant="h6" component="div">
                    {user ? user.username : "Unknown User"}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Created on:{" "}
                    {new Date(website.creationDate).toLocaleDateString()}
                  </Typography>
                </CardContent>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => window.open(website.url, "_blank")}
                >
                  View Website
                </Button>
              </StyledCard>
            </Grid>
          );
        })}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
        <Pagination
          count={Math.ceil(filteredWebsites.length / websitesPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default WebsitesList;
