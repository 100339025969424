import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import { toast } from "react-toastify";

const AuthRedirect = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useUserContext();
  const loginPath = "/login"; // Path to the login page
  const registerPath = "/register"; // Path to the register page

  useEffect(() => {
    if (isAuthenticated) {
      // Redirect authenticated users away from login and register pages
      if (location.pathname === loginPath || location.pathname === registerPath) {
        toast.warn("You are already logged in.");
        const from = location.state?.from?.pathname || '/profile'; // Default to /profile if no previous location
        navigate(from); // Redirect to the previous page or profile
      }
    } else {
      // Redirect unauthenticated users to login page when trying to access protected routes
      if (location.pathname !== loginPath && location.pathname !== registerPath) {
        toast.warn("Please log in to access this page.");
        navigate(loginPath, { state: { from: location } });
      }
    }
  }, [isAuthenticated, navigate, location, loginPath, registerPath]);

  // Render children only if conditions are met
  if (isAuthenticated && (location.pathname === loginPath || location.pathname === registerPath)) {
    return null; // Prevent rendering login or register components for authenticated users
  }

  return children;
};

export default AuthRedirect;
