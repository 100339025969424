// theme.js
import { createTheme } from '@mui/material/styles';

// Define your custom theme
const theme = createTheme({
    palette: {
        primary: {
            lighter: "#C8FAD6",
            light: "#5BE49B",
            main: '#00A76F',
            dark: "#007867",
            darker: "#004B50",
        },
        secondary: {
            lighter: "#EFD6FF",
            light: "#C684FF",
            main: '#8E33FF',
            dark: "#5119B7",
            darker: "#27097A",
        },
        info: {
            lighter: "#CAFDF5",
            light: "#61F3F3",
            main: '#00B8D9',
            dark: "#006C9C",
            darker: "#003768",
        },
        success: {
            lighter: "#D3FCD2",
            light: "#77ED8B",
            main: '#22C55E',
            dark: "#118D57",
            darker: "#065E49",
        },
        warning: {
            lighter: "#FFF5CC",
            light: "#FFD666",
            main: '#FFAB00',
            dark: "#B76E00",
            darker: "#7A4100",
        },
        error: {
            lighter: "#FFE9D5",
            light: "#FFAC82",
            main: '#FF5630',
            dark: "#B71D18",
            darker: "#7A0916",
        },
        grey: {
            100: "#F9FAFB",
            200: "#F4F6F8",
            300: '#DFE3E8',
            400: "#C4CDD5",
            500: "#919EAB",
            600: "#637381",
            700: "#454F5B",
            800: "#212B36",
            900: "#161C24",
        },
        text: {
            primary: '#000000',
            secondary: '#757575',
            disabled: '#BDBDBD',
        },
    },
    typography: {
        h1: {
            fontSize: '64px',
            lineHeight: '80px',
            fontWeight: 800,
            letterSpacing: 0,
        },
        h2: {
            fontSize: '48px',
            lineHeight: '64px',
            fontWeight: 800,
            letterSpacing: 0,
        },
        h3: {
            fontSize: '32px',
            lineHeight: '48px',
            fontWeight: 700,
            letterSpacing: 0,
        },
        h4: {
            fontSize: '24px',
            lineHeight: '36px',
            fontWeight: 700,
            letterSpacing: 0,
        },
        h5: {
            fontSize: '20px',
            lineHeight: '30px',
            fontWeight: 700,
            letterSpacing: 0,
        },
        h6: {
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: 700,
            letterSpacing: 0,
        },
        subtitle1: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 600,
            letterSpacing: 0,
        },
        subtitle2: {
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: 600,
            letterSpacing: 0,
        },
        body1: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 400,
            letterSpacing: 0,
        },
        body2: {
            fontSize: '14px',
            lineHeight: '22px',
            fontWeight: 400,
            letterSpacing: 0,
        },
        caption: {
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 400,
            letterSpacing: 0,
        },
        overline: {
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 700,
            letterSpacing: 0,
        },
        button: {
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 700,
            letterSpacing: 0,
            marginTop:'10px'
        },
    },
    spacing: 8, // Define spacing unit
});

export default theme;
