import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Divider } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import { useUserContext } from '../../context/UserContext';

const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { isAuthenticated } = useUserContext(); // Get authentication state from user context

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const drawer = (
        <div
            sx={{
                width: 250,
                backgroundColor: theme.palette.grey[800],
                color: theme.palette.grey[100],
                height: '100%',
            }}
        >
            <List>
                <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
                    <ListItemText primary="Home" />
                </ListItem>
                {!isAuthenticated && (
                    <>
                        <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                            <ListItemText primary="Login" />
                        </ListItem>
                        <ListItem button component={Link} to="/register" onClick={handleDrawerToggle}>
                            <ListItemText primary="Register" />
                        </ListItem>
                    </>
                )}
                {isAuthenticated && (
                    <ListItem button component={Link} to="/profile" onClick={handleDrawerToggle}>
                        <ListItemText primary="Profile" />
                    </ListItem>
                )}
            </List>
            <Divider sx={{ backgroundColor: theme.palette.grey[700] }} />
        </div>
    );

    return (
        <>
            <AppBar position="static" sx={{ backgroundColor: theme.palette.grey[900] }}>
                <Toolbar>
                    {isMobile ? (
                        <>
                            <IconButton edge="start" color="inherit" onClick={handleDrawerToggle}>
                                <MenuIcon />
                            </IconButton>
                            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                                {drawer}
                            </Drawer>
                        </>
                    ) : (
                        <>
                            <IconButton edge="start" color="inherit" sx={{ marginRight: 2 }} aria-label="home" component={Link} to="/">
                                <HomeIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                <Link to="/" style={{ textDecoration: 'none', color: theme.palette.primary.contrastText }}>Brand Whisperer</Link>
                            </Typography>
                            {!isAuthenticated && (
                                <>
                                    <Button
                                        color="inherit"
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            },
                                            marginLeft: 1
                                        }}
                                        component={Link} to="/login"
                                    >
                                        <AccountCircleIcon sx={{ marginRight: 1 }} />
                                        Login
                                    </Button>
                                    <Button
                                        color="inherit"
                                        sx={{
                                            backgroundColor: theme.palette.secondary.main,
                                            '&:hover': {
                                                backgroundColor: theme.palette.secondary.dark,
                                            },
                                            marginLeft: 1
                                        }}
                                        component={Link} to="/register"
                                    >
                                        Register
                                    </Button>
                                </>
                            )}
                            {isAuthenticated && (
                                <Button
                                    color="inherit"
                                    sx={{
                                        backgroundColor: theme.palette.primary.main,
                                        '&:hover': {
                                            backgroundColor: theme.palette.primary.dark,
                                        },
                                        marginLeft: 1
                                    }}
                                    component={Link} to="/profile"
                                >
                                    <AccountCircleIcon sx={{ marginRight: 1 }} />
                                    Profile
                                </Button>
                            )}
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Navbar;
