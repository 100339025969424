import React, { useEffect, useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Box,
  Paper,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function EmailTemplateGenerator() {
  const [activeStep, setActiveStep] = useState(0);
  const [emailInfo, setEmailInfo] = useState({
    subject: "",
    description: "",
    category: "",
    recipient: "",
  });
  const [emailTemplate, setEmailTemplate] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [loading, setLoading] = useState(false);
  const [sender, setSender] = useState("");

  const steps = ["Enter Email Info", "Preview Template", "Send Email"];
  const stepDescriptions = [
    "Provide the subject, description, and category for your email. These details will be used to generate your custom email template.",
    "Review your generated email template. You can regenerate it if it doesn’t meet your expectations.",
    "Send your finalized email template.",
  ];

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      const user = decodedToken.user;
      console.log(user.email);
      setSender(user.email);
    }
  }, []);
  const handleNext = () => {
    if (activeStep === 0) {
      fetchEmailTemplate();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setEmailInfo({ subject: "", description: "", category: "", recipient: "" });
    setEmailTemplate("");
  };

  const fetchEmailTemplate = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://localhost:3088/api/emails/generate",
        emailInfo,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let emailContent = response.data;

      // Remove unwanted parts from the response
      const startIndex = emailContent.indexOf("```html");
      const endIndex = emailContent.lastIndexOf("```");

      if (startIndex !== -1 && endIndex !== -1) {
        emailContent = emailContent.slice(startIndex + 7, endIndex);
      }

      setEmailTemplate(emailContent);
    } catch (error) {
      handleSnackbarOpen("Error generating email template.", "error");
    }
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEmailInfo((prev) => ({ ...prev, [name]: value }));
  };

  const sendEmail = async () => {
    setLoading(true);
    try {
      await axios.post(
        "http://localhost:3088/api/emails/send",
        {
          subject: emailInfo.subject,
          body: emailTemplate,
          recipient: emailInfo.recipient,
          sender: sender,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      handleSnackbarOpen("Email sent successfully!", "success");
    } catch (error) {
      handleSnackbarOpen("Error sending email.", "error");
    }
    setLoading(false);
  };

  const downloadTemplate = () => {
    const fileName = emailInfo.subject
      ? `${emailInfo.subject.replace(/\s+/g, "_")}.html`
      : "emailTemplate.html";
    const element = document.createElement("a");
    const file = new Blob([emailTemplate], { type: "text/html" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const stepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Subject"
              name="subject"
              value={emailInfo.subject}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Description"
              name="description"
              value={emailInfo.description}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Category"
              name="category"
              value={emailInfo.category}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Recipient Email"
              name="recipient"
              value={emailInfo.recipient}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Box>
        );
      case 1:
        return (
          <Paper elevation={3} style={{ padding: "20px", marginTop: "20px" }}>
            <Typography variant="h5" gutterBottom>
              Preview Your Email Template
            </Typography>
            <div
              className="preview-template"
              dangerouslySetInnerHTML={{ __html: emailTemplate }}
            />
            <Button
              variant="outlined"
              color="secondary"
              onClick={fetchEmailTemplate}
              style={{ marginTop: "20px" }}
              disabled={loading}
            >
              {loading ? "Regenerating..." : "Regenerate"}
            </Button>
          </Paper>
        );
      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h5" gutterBottom>
              Send Your Email Template
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={sendEmail}
              style={{ marginRight: "10px" }}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send Email"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={downloadTemplate}
            >
              Download Template
            </Button>
          </Box>
        );
      default:
        return "Unknown Step";
    }
  };

  return (
    <div className="container mt-5">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              {label}
              <Tooltip title={stepDescriptions[index]}>
                <InfoIcon
                  color="action"
                  style={{ marginLeft: "5px", verticalAlign: "bottom" }}
                />
              </Tooltip>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <Box sx={{ mt: 2 }}>
            <Typography>
              All steps completed - your email template is ready!
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        ) : (
          <div>
            <Paper elevation={2} sx={{ padding: "20px", marginBottom: "20px" }}>
              <Typography variant="body1" color="textSecondary">
                {stepDescriptions[activeStep]}
              </Typography>
            </Paper>
            {stepContent(activeStep)}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                className="me-auto"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </div>
        )}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default EmailTemplateGenerator;
