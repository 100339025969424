import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Collapse,
} from "@mui/material";
import {
  Dashboard,
  Person,
  Settings,
  Security,
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
  Web,
  Article,
  Email,
  ExitToApp,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useUserContext } from "../../context/UserContext";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.background.paper, // Adjust background color
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  backgroundColor: theme.palette.background.default, // Adjust background color
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminSidebar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [platformOpen, setPlatformOpen] = useState(false);
  const { setIsAuthenticated, setUserRole } = useUserContext();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handlePlatformClick = () => {
    setPlatformOpen(!platformOpen);
  };
  const handleLogout = () => {
    // Clear authentication token and reset user context
    localStorage.removeItem("authToken");
    setIsAuthenticated(false);
    setUserRole("");
    window.location.href = "/login";
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {/** All ListItems are updated here with hover and active effects */}
        <ListItem button component={Link} to="/admin-profile">
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
        <ListItem button component={Link} to="/admin/dashboard">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/admin/users">
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <Divider />
        <ListItem button onClick={handlePlatformClick}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Manage Platform" />
          {platformOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={platformOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              component={Link}
              to="/admin/manage-website-templates"
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <Web />
              </ListItemIcon>
              <ListItemText primary="Website Templates" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/manage-posts"
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <Article />
              </ListItemIcon>
              <ListItemText primary="Posts" />
            </ListItem>
            <ListItem
              button
              component={Link}
              to="/admin/manage-email-templates"
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <Email />
              </ListItemIcon>
              <ListItemText primary="Emails Templates" />
            </ListItem>
          </List>
        </Collapse>
        <List>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </List>
    </StyledDrawer>
  );
};

export default AdminSidebar;
