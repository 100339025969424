import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Pagination,
  Box,
  TextField,
  CardMedia,
  Modal,
  CardActions,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import EventIcon from "@mui/icons-material/Event";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
    boxShadow: theme.shadows[10],
  },
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  maxWidth: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
  borderRadius: 2,
  overflow: "hidden",
  maxHeight: "80vh",
};

const contentStyle = {
  maxHeight: "60vh",
  overflowY: "auto",
};

const truncateContent = (content, maxLength) => {
  if (content.length <= maxLength) return content;
  return content.substring(0, maxLength) + "...";
};

const PostsList = () => {
  const [posts, setPosts] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(4);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get("http://localhost:3088/api/posts");
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleOpen = (post) => {
    setSelectedPost(post);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filteredPosts = posts.filter((post) =>
    post.author.username.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <TextField
        label="Search by Author"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        sx={{ mb: 2, maxWidth: 600 }}
      />
      <Grid container justifyContent="center" spacing={2}>
        {currentPosts.map((post) => (
          <Grid item key={post._id}>
            <StyledCard>
              <CardMedia
                component="img"
                height="140"
                image={post.imageUrl || "https://via.placeholder.com/140"}
                alt={`Cover image for ${post.title}`}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {post.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {truncateContent(post.content, 100)}
                </Typography>
                <Button size="small" onClick={() => handleOpen(post)}>
                  Read more
                </Button>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <PersonIcon color="action" />
                  <Typography variant="body2" sx={{ ml: 0.5 }}>
                    {post.author.username}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                  <EventIcon color="action" />
                  <Typography
                    variant="caption"
                    sx={{ ml: 0.5 }}
                  >{`Posted on: ${new Date(
                    post.createdAt
                  ).toLocaleDateString()}`}</Typography>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            {selectedPost && (
              <>
                <IconButton
                  onClick={handleClose}
                  sx={{ position: "absolute", right: 8, top: 8, color: "gray" }}
                >
                  <CloseIcon />
                </IconButton>
                <CardMedia
                  component="img"
                  image={
                    selectedPost.imageUrl || "https://via.placeholder.com/600"
                  }
                  alt={`Cover image for ${selectedPost.title}`}
                  sx={{ width: "100%", maxHeight: "300px", objectFit: "cover" }}
                />
                <Box sx={{ mt: 2, p: 2, ...contentStyle }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold" }}
                  >
                    {selectedPost.title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 1 }}>
                    {selectedPost.content}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: 2 }}
                  >
                    Author: {selectedPost.author.username}
                  </Typography>
                  <Typography variant="caption" display="block" sx={{ mb: 2 }}>
                    Posted on:{" "}
                    {new Date(selectedPost.createdAt).toLocaleDateString()}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </Grid>
      <Pagination
        count={Math.ceil(filteredPosts.length / postsPerPage)}
        page={currentPage}
        onChange={(e, page) => setCurrentPage(page)}
        color="primary"
        sx={{ mt: 4, display: "flex", justifyContent: "center" }}
      />
    </Box>
  );
};

export default PostsList;
