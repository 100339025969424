import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import {
  Home,
  Person,
  Settings,
  ChevronLeft,
  ChevronRight,
  Web,
  Email,
  PostAdd,
  ExitToApp,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { useUserContext } from "../../context/UserContext";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const UserSidebar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { setIsAuthenticated, setUserRole } = useUserContext();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    // Clear authentication token and reset user context
    localStorage.removeItem("authToken");
    setIsAuthenticated(false);
    setUserRole("");
    window.location.href = "/login";
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Box sx={{ flexGrow: 1 }}>
        <List>
          <ListItem button component={Link} to="/">
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/profile">
            <ListItemIcon>
              <Person />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItem>

          <Divider />
          <ListItem button component={Link} to="/website-generator">
            <ListItemIcon>
              <Web />
            </ListItemIcon>
            <ListItemText primary="Website Generator" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/email-generator">
            <ListItemIcon>
              <Email />
            </ListItemIcon>
            <ListItemText primary="Email Generator" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/post-blog-generator">
            <ListItemIcon>
              <PostAdd />
            </ListItemIcon>
            <ListItemText primary="Post & Blog Generator" />
          </ListItem>
          <Divider />
        </List>
      </Box>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </StyledDrawer>
  );
};

export default UserSidebar;
