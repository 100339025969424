import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Avatar,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { jwtDecode } from "jwt-decode"; // Note: Correct import should be from 'jwt-decode'
import dayjs from "dayjs";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserProfile() {
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordChanging, setIsPasswordChanging] = useState(false);


  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const decodedToken = jwtDecode(token);
      const user = decodedToken.user;

      setUsername(user.username);
      setEmail(user.email);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setDateOfBirth(dayjs(user.dateOfBirth));
    }
  }, []);

  const handleUpdate = async () => {
    const token = localStorage.getItem("authToken");
    const userId = jwtDecode(token).user._id;
    const updateData = {
      username,
      email,
      firstName,
      lastName,
      dateOfBirth: dateOfBirth ? dateOfBirth.format("YYYY-MM-DD") : null,
    };

    if (isPasswordChanging && password) {
      updateData.password = password;
    }

    try {
      const response = await axios.put(
        `http://localhost:3088/api/users/user/${userId}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      localStorage.setItem("authToken", response.data.token);
      if (response.status === 200) {
        toast.success("Profile updated successfully!");
        // Consider forcing a logout here if the password was changed
        if (isPasswordChanging) {
          localStorage.removeItem("authToken");
          window.location.href = "/login";
        }
      }
    } catch (error) {
      console.error("There was an error updating the profile!", error);
      toast.error("There was an error updating the profile!");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          padding: 3,
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          User Profile
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Avatar
            alt="Profile Image"
            sx={{ width: 100, height: 100, fontSize: 40, mb: 2 }}
          >
            {username.charAt(0).toUpperCase()}
          </Avatar>
          <Typography variant="h6">{username}</Typography>
        </Box>
        <Grid container spacing={3} sx={{ maxWidth: 600 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPasswordChanging}
                  onChange={() => setIsPasswordChanging(!isPasswordChanging)}
                  color="primary"
                />
              }
              label="Change Password"
            />
            {isPasswordChanging && (
              <TextField
                fullWidth
                label="New Password"
                variant="outlined"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              label="Date of Birth"
              value={dateOfBirth}
              onChange={(newValue) => setDateOfBirth(newValue)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 4 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleUpdate}
          >
            Update Profile
          </Button>
        </Box>
        <ToastContainer />
      </Box>
    </LocalizationProvider>
  );
}

export default UserProfile;
