import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import {
  Container,
  Box,
  CircularProgress,
  Alert,
  Drawer,
  Typography,
  Divider,
  Select,
  MenuItem,
  Button,
  TextField,
  Card,
  CardMedia,
  AppBar,
  Toolbar,
  CssBaseline,
  Dialog,
  DialogContent,
} from "@mui/material";
import { BlockPicker } from "react-color";
import { useWebsite } from "../../context/WebsiteContext";
import UpdateIcon from "@mui/icons-material/Update";
import CodeIcon from "@mui/icons-material/Code";
import PublishIcon from "@mui/icons-material/Publish";

const drawerWidth = 240;

const WebSite = () => {
  const { website, loading, error } = useWebsite();

  const [primaryColor, setPrimaryColor] = useState("#ffffff");
  const [secondaryColor, setSecondaryColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Arial");
  const [selectedText, setSelectedText] = useState("");
  const [selectedImageSrc, setSelectedImageSrc] = useState("");
  const [selectedImageElement, setSelectedImageElement] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const contentRef = useRef(null);

  const handleColorChange = (color, type) => {
    if (type === "primary") {
      setPrimaryColor(color.hex);
    } else {
      setSecondaryColor(color.hex);
    }
    document.documentElement.style.setProperty(`--${type}-color`, color.hex);
  };

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
    document.documentElement.style.setProperty(
      "--font-family",
      event.target.value
    );
  };

  const applyStyles = () => {
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondaryColor
    );
    document.documentElement.style.setProperty("--font-family", fontFamily);
  };

  const handleTextChange = (event) => {
    setSelectedText(event.target.value);
    const element = contentRef.current.querySelector(`[data-selected='true']`);
    if (element) {
      element.innerText = event.target.value;
    }
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const response = await axios.post(
          "http://localhost:3088/api/upload/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const imageUrl = response.data.imageUrl;

        if (selectedImageElement) {
          const width = selectedImageElement.width;
          const height = selectedImageElement.height;
          selectedImageElement.src = imageUrl;
          selectedImageElement.width = width;
          selectedImageElement.height = height;
          setSelectedImageSrc(imageUrl);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleImageClick = (event) => {
    event.stopPropagation();
    if (selectedImageElement) {
      selectedImageElement.classList.remove("selected-element");
    }
    const element = event.target;
    element.classList.add("selected-element");
    setSelectedImageElement(element);
    setSelectedImageSrc(element.src);
    setSelectedText(""); // Clear text selection state
  };

  const handleSelection = (event) => {
    if (event.target.closest(".MuiDrawer-root")) {
      return;
    }

    if (window.getSelection) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const selectedElement = selection.anchorNode.parentElement;
        if (selectedElement.tagName !== "IMG") {
          if (selectedImageElement) {
            selectedImageElement.classList.remove("selected-element");
          }
          if (contentRef.current.querySelector(`[data-selected='true']`)) {
            contentRef.current
              .querySelector(`[data-selected='true']`)
              .removeAttribute("data-selected");
          }
          selectedElement.setAttribute("data-selected", "true");
          setSelectedText(selectedElement.innerText);
          setSelectedImageSrc(""); // Clear image selection state
        }
      }
    }
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      const images = contentElement.getElementsByTagName("img");
      for (let img of images) {
        img.addEventListener("click", handleImageClick);
      }
    }

    document.addEventListener("mouseup", handleSelection);
    return () => {
      if (contentElement) {
        const images = contentElement.getElementsByTagName("img");
        for (let img of images) {
          img.removeEventListener("click", handleImageClick);
        }
      }
      document.removeEventListener("mouseup", handleSelection);
    };
  }, [handleImageClick, handleSelection]);

  const handleUpdate = async () => {
    try {
      const updatedContent = `\`\`\`html\n${contentRef.current.innerHTML}\n\`\`\``;
      console.log(updatedContent);
      const response = await axios.put(
        `http://localhost:3088/api/websites/websites/${website._id}/content`,
        {
          content: updatedContent,
        }
      );
      console.log("Update successful:", response.data);
    } catch (error) {
      console.error("Error updating website:", error);
    }
  };

  const handleCodeDownload = () => {
    const code = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${website.title}</title>
    <style>
        /* Add your styles here */
    </style>
</head>
<body>
${contentRef.current.innerHTML}
</body>
</html>`;
    const blob = new Blob([code], { type: "text/html" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "website.html";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePublish = async () => {
    setIsPublishing(true);
    try {
      const updatedContent = contentRef.current.innerHTML;
      const response = await axios.post(
        `http://localhost:3088/api/websites/websites/${website._id}/publish`,
        {
          content: updatedContent,
        }
      );
      console.log("Publish successful:", response.data);
      window.open(response.data.url, "_blank");
    } catch (error) {
      console.error("Error publishing website:", error);
    } finally {
      setIsPublishing(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!website) {
    return (
      <Container sx={{ mt: 4 }}>
        <Alert severity="info">No website data found</Alert>
      </Container>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          bgcolor: "#ffffff",
          color: "#000000",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <Button
            color="inherit"
            sx={{ mr: 2 }}
            startIcon={<UpdateIcon />}
            onClick={handleUpdate}
          >
            Update
          </Button>
          <Button
            color="inherit"
            sx={{ mr: 2 }}
            startIcon={<CodeIcon />}
            onClick={handleCodeDownload}
          >
            Code
          </Button>
          <Button
            color="inherit"
            startIcon={<PublishIcon />}
            onClick={handlePublish}
          >
            Publish
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto", p: 2 }}>
          <Typography variant="h6">Customize Website</Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1">Primary Color</Typography>
          <BlockPicker
            color={primaryColor}
            onChangeComplete={(color) => handleColorChange(color, "primary")}
          />
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1">Secondary Color</Typography>
          <BlockPicker
            color={secondaryColor}
            onChangeComplete={(color) => handleColorChange(color, "secondary")}
          />
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1">Font Family</Typography>
          <Select
            value={fontFamily}
            onChange={handleFontFamilyChange}
            fullWidth
          >
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Verdana">Verdana</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Courier New">Courier New</MenuItem>
          </Select>
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1">Selected Text</Typography>
          <TextField
            value={selectedText}
            onChange={handleTextChange}
            fullWidth
            multiline
          />
          {selectedImageSrc && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Selected Image</Typography>
              <Card sx={{ mt: 1, mb: 2 }}>
                <CardMedia
                  component="img"
                  image={selectedImageSrc}
                  alt="Selected"
                />
              </Card>
              <Button variant="contained" component="label" fullWidth>
                Upload New Image
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  hidden
                />
              </Button>
            </Box>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={applyStyles}
            sx={{ mt: 2 }}
          >
            Apply Styles
          </Button>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: 8,
          backgroundColor: "var(--primary-color)",
          color: "var(--secondary-color)",
          fontFamily: "var(--font-family)",
        }}
      >
        <Box
          ref={contentRef}
          dangerouslySetInnerHTML={{ __html: website.content }}
        />
      </Box>
      <Dialog open={isPublishing}>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
            }}
          >
            <CircularProgress />
            <Typography sx={{ ml: 2 }}>Publishing...</Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <style>
        {`
          .selected-element {
            outline: 2px solid red;
          }
        `}
      </style>
    </Box>
  );
};

export default WebSite;
