import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import loginImage from "../../assets/loginImage.jpg";
import BackgroundAuth from "../../assets/backgroundAuth.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useUserContext } from "../../context/UserContext";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
    backgroundImage: `url(${BackgroundAuth})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image: {
    backgroundImage: `url(${loginImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  },
  paperContainer: {
    display: "flex",
    minHeight: "80vh",
    borderRadius: 15,
    overflow: "hidden",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    flexGrow: 1,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 2, 2),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const Login = () => {
  const classes = useStyles();
  const [form, setForm] = useState({ email: "", password: "" });
  const { setIsAuthenticated, setUserRole } = useUserContext();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:3088/api/users/login", form)
      .then((response) => {
        const { token } = response.data;
        const decodedToken = jwtDecode(token);
        localStorage.setItem("authToken", token);
        setIsAuthenticated(true);
        const userRole = decodedToken.user.roles[0];
        setUserRole(userRole);
        console.log(userRole);

        // Redirect based on user role
        if (userRole === "admin") {
          navigate("/admin-profile");
        } else {
          navigate("/profile");
        }
      })
      .catch((error) => {
        console.error(error); // Handle error
      });
  };

  return (
    <Container component="main" maxWidth="xl" className={classes.root}>
      <Paper elevation={10} className={classes.paperContainer}>
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.image} />
          <Grid item xs={12} sm={6} component={Paper} elevation={0} square>
            <Box className={classes.formContainer}>
              <Typography component="h1" variant="h4" gutterBottom>
                Login
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Log in to your account
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={form.password}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                  >
                    Login
                  </Button>
                </Grid>
              </form>
              <Typography variant="body1" gutterBottom>
                If you don't have an account, register{" "}
                <span role="img" aria-label="heart">
                  ❤️
                </span>
                <Link to="/register" className={classes.link}>
                  here
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Login;
