import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import registerImage from "../../assets/background.png";
import BackgroundAuth from "../../assets/backgroundAuth.jpg";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import { toast, ToastContainer } from "react-toastify"; // Import if not already imported
import "react-toastify/dist/ReactToastify.css"; // Import CSS for toast if not already imported

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
    backgroundImage: `url(${BackgroundAuth})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  image: {
    backgroundImage: `url(${registerImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
  },
  paperContainer: {
    display: "flex",
    minHeight: "80vh",
    borderRadius: 15,
    overflow: "hidden",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    flexGrow: 1,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 2, 2),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const Register = () => {
  const classes = useStyles();
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    dateOfBirth: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://localhost:3088/api/users/register",
        form
      );
      console.log("Form submitted:", form);
      console.log("Server response:", response.data);
      toast.success("Registration successful");
      window.location.href = "/login"; // Redirecting to login after successful registration
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast.error("Email already exists. Please use a different email."); // Custom message for 409 status
      } else {
        toast.error(
          error.response ? error.response.data.message : "Registration failed"
        );
      }
      console.error(
        "Registration failed:",
        error.response ? error.response.data : error
      );
    }
  };

  return (
    <Container component="main" maxWidth="xl" className={classes.root}>
      <Paper elevation={10} className={classes.paperContainer}>
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.image} />
          <Grid item xs={12} sm={6} component={Paper} elevation={0} square>
            <Box className={classes.formContainer}>
              <Typography component="h1" variant="h4" gutterBottom>
                Register
              </Typography>
              <Typography variant="body1" color="textSecondary" gutterBottom>
                Create your account to start using our services
              </Typography>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      autoComplete="firstName"
                      value={form.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      autoComplete="lastName"
                      value={form.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="username"
                      label="Username"
                      name="username"
                      autoComplete="username"
                      value={form.username}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="dateOfBirth"
                      label="Date of Birth"
                      type="date"
                      id="dateOfBirth"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={form.dateOfBirth}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={form.password}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={classes.submit}
                >
                  Register
                </Button>
                <Grid item xs={12}>
                  <Typography variant="body2" component="p">
                    If you already have an account, please log in with your
                    credentials{" "}
                    <span role="img" aria-label="heart">
                      ❤️
                    </span>
                    <Link to="/login" className={classes.link}>
                      here
                    </Link>
                  </Typography>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default Register;
