import React from "react";
import { Container } from "@mui/material";
import Navbar from "../components/navigation/Navbar";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Card, CardContent, CardMedia, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import backgroundImg from "../assets/background.png";
import feature1Img from "../assets/feature1.jpg";
import feature2Img from "../assets/feature2.jpg";
import feature3Img from "../assets/feature3.png";
import Footer from "../components/footer/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    textAlign: "center",
  },
  card: {
    maxWidth: 345,
    margin: "auto",
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[3],
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[6],
    },
  },
  media: {
    height: 200,
    borderRadius: theme.spacing(2, 2, 0, 0),
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="xl" disableGutters="true">
      <Navbar />
      <div
        style={{
          background: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "100vh",
        }}
      >
        <div className={classes.root}>
          <Typography variant="h2" gutterBottom color={"white"}>
            Welcome to AI Generator
          </Typography>
          <Typography variant="body1" paragraph color={"white"}>
            Generate text, images, code, and more with the power of AI!
          </Typography>
          <Typography variant="body1" paragraph color={"white"}>
            Whether you need creative inspiration, assistance with writing, or
            help with coding, our AI models are here to assist you.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/generator"
            style={{ margin: "20px" }}
          >
            Get Started
          </Button>
        </div>
        <div className={classes.root}>
          <Typography variant="h4" gutterBottom>
            Features
          </Typography>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image={feature1Img}
                  title="Website Generation"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Website Generation
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Empower your online presence effortlessly with our
                    innovative website generation tools.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image={feature2Img}
                  title="Email Generation"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Email Generation
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Simplify your communication strategy with our intelligent
                    email generation solutions.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card className={classes.card}>
                <CardMedia
                  className={classes.media}
                  image={feature3Img}
                  title="  Blog and Posts Generation"
                />
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Blog and Posts Generation
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Elevate your content creation process with our advanced blog
                    and post generation capabilities.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <Footer />
    </Container>
  );
};

export default Home;
